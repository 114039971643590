<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="resetPass"
      :title="$t('Change Password')"
      :buttons-hidden="true">
        <div class="vx-row mb-2">              
          <template>
            <div class="vx-col w-1/2">
              <vs-input ref="password" type="password" v-validate="{ required: true, regex: /^(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}$/ }" :label-placeholder="$t('Password')" name="password" v-model="data.password" class="mt-10 w-full" />
              <span class="text-danger text-sm" v-show="errors.has('password')">{{ errors.first('password') }}</span>
            </div>
            <div class="vx-col w-1/2">
              <vs-input type="password" v-validate="{ required: true, confirmed:'password' ,regex: /^(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}$/ }" :label-placeholder="$t('Confirm Password')" name="confirm password" v-model="data.confirm_password" class="mt-10 w-full" data-vv-as="password" />
              <span class="text-danger text-sm" v-show="errors.has('confirm password')">{{ errors.first('confirm password') }}</span>
            </div>
          </template>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
          <button @click="changePassword" class="active-btn btn">{{ $t('Change') }}</button>
        </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import ShipbluPrompt from '../../../../layouts/components/ShipBluPrompt.vue'
import i18nData from '../../../../i18n/i18nData.js'
import { sendFulfillmentRequest } from '../../../../http/axios/requestHelper'

export default {
  props: ['userData', 'resetPass', 'userRole'],
  data () {
    return {
      data: {}
    }
  },
  methods: {
    changePassword () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const obj = {
            first_name: this.userData.name.split(' ')[0],
            last_name: this.userData.name.split(' ')[1],
            password: this.data.password
          }
          if (!this.isOnline) this.$emit('resetPass', false)
          sendFulfillmentRequest(false, false, this, `api/v1/fc/users/${this.userRole}/${this.userData.user_id}/`, 'patch', obj, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Password'] + i18nData[this.$i18n.locale][' has been changed successfully!'],
                position: 'top-center'
              })
              this.$emit('resetPass', false)
              this.$emit('loadData')
            }
          )
        }
      })
    },
    closeModal () {
      this.$emit('resetPass', false)
    }
  },
  components: {
    ShipbluPrompt
  }
}
</script>